/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './button.scss'

const Button = ({
  iconAlt, icon, text, buttonType, onClick, classButton, iconClass, title, underline, isSeason, seasonIndex,
}) => {
  const [className, setClassName] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  const buttonBehaviour = () => {
    if (buttonType === 'home') {
      navigate('/browse')
    } else if (buttonType === 'logo') {
      navigate('/')
    } else if (buttonType === 'changeProfile') {
      navigate('/chooseProfile')
    } else if (onClick) {
      onClick()
    } else if (text === 'avatars') {
      navigate('/chooseProfile')
    } else {
      navigate(`/${text.toLowerCase().replace(/\s/g, '')}`)
    }
  }

  useEffect(() => {
    if (location.pathname.toLowerCase().includes(text?.toLowerCase())
      || (buttonType === 'settings' && location.pathname.toLowerCase().includes('settings'))
    ) {
      setClassName('active')
    } else {
      setClassName('')
    }
  }, [location.pathname])

  return (
    <button title={title} type="button" className={classButton || className} onClick={() => buttonBehaviour()}>
      { icon ? <img className={iconClass} alt={iconAlt} src={icon} /> : '' }
      { text === 'avatars' || !text ? '' : <span>{text && buttonType !== 'settings' ? t(`links.${text}`, isSeason ? { index: seasonIndex } : null) : text}</span> }
      { underline ? <div className="underline" /> : '' }
    </button>
  )
}

Button.propTypes = {
  iconAlt: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  classButton: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  underline: PropTypes.bool,
  isSeason: PropTypes.bool,
  seasonIndex: PropTypes.number,
}

Button.defaultProps = {
  iconAlt: undefined,
  icon: undefined,
  text: undefined,
  buttonType: undefined,
  onClick: undefined,
  classButton: undefined,
  iconClass: '',
  title: undefined,
  underline: false,
  isSeason: false,
  seasonIndex: undefined,
}

export default Button
