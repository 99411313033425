/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Episodes from '../../detailsRelated/Episodes'
import Carousels from '../../carouselRelated/carousel/Carousels'
import './tabSelector.scss'
import Button from '../button/Button'
import MoreDetails from '../../detailsRelated/MoreDetails'
import { fetchCurrentSeason } from '../../../store/content/content-actions'

const TabSelector = ({ tabs, contentId, currentContentInfo }) => {
  const [selectedOption, setSelectedOption] = useState(0)
  const [currentSeason, setCurrentSeason] = useState(1)
  const dispatch = useDispatch()
  const episodesInfo = useSelector((state) => state.content.episodesInfo)
  const recommendationsCarousel = useSelector((state) => state.content.recommendationsCarousel)

  const selectSeason = (seasonNumber) => {
    setCurrentSeason(seasonNumber)
    dispatch(fetchCurrentSeason(contentId, seasonNumber))
  }

  return (
    <>
      <div className={`options-selector options-${tabs.length}`}>
        {
          tabs.map((item, index) => (
            <Button
              key={`tab-options-${index}`}
              text={item}
              underline
              classButton={`option ${index === selectedOption ? 'selected-option' : ''}`}
              onClick={() => setSelectedOption(index)}
            />
          ))
        }
      </div>

      <div className="rendered-option">
        { tabs[selectedOption] === 'Episodes' && episodesInfo ? (
          <>
            <div className="seasons-selector">
              {
              currentContentInfo?.seasons?.map((item) => (
                <Button
                  key={`tab-season-${item}`}
                  text="season"
                  classButton={`option ${item === currentSeason ? 'selected-option' : ''}`}
                  onClick={() => selectSeason(item)}
                  isSeason
                  seasonIndex={item}
                />
              ))
            }
            </div>
            <Episodes episodes={episodesInfo} />
          </>
        ) : ''}
        { tabs[selectedOption] === 'Recommendations' ? <Carousels recommended carousels={recommendationsCarousel} /> : ''}
        { tabs[selectedOption] === 'Details' ? <MoreDetails currentContentInfo={currentContentInfo} /> : ''}
      </div>
    </>
  )
}

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  contentId: PropTypes.string.isRequired,
  currentContentInfo: PropTypes.shape({
    synopsis: PropTypes.string.isRequired,
    cast: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.string,
    releaseYear: PropTypes.number.isRequired,
    ageRestriction: PropTypes.string.isRequired,
    seasons: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
}

TabSelector.defaultProps = {
  tabs: ['Recommendations', 'Details'],
}

export default TabSelector
