import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import carouselsJson from '../../../../assets/json/carousels.json'
import Button from '../../extraComponents/button/Button'
import backArrow from '../../../../assets/images/backArrow.png'
import useScreen from '../../Observer'
import './autoScrollCarousel.scss'
import { fetchCurrentContent } from '../../../store/content/content-actions'

const AutoScrollCarousel = ({ buttonFunc }) => {
  const previewRef = React.useRef()
  const previewContainerRef = React.useRef()
  const observer = useScreen(previewContainerRef, 1)

  const [active, setActive] = React.useState(0)
  const [carouselContainer, setcarouselContainer] = useState([])
  const [notIsDesktop, setNotIsDesktop] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (carouselsJson[0]?.type === 'banner') {
      setcarouselContainer(carouselsJson[1])
    }
  }, [carouselsJson])

  /**
   * Observer for the carousel:
   *  observer true if user is watching the carousel
   *  observer false if user is not watching the carousel
   * */
  useEffect(() => {
    let autoScroll
    if (observer) {
      autoScroll = setInterval(() => {
        setActive((oldActive) => (oldActive + 1) % Math.ceil(carouselsJson[1]?.items?.length))
      }, 3000)
    }
    return () => clearInterval(autoScroll)
  }, [observer])

  useEffect(() => {
    previewRef.current.scrollLeft = Math.ceil((notIsDesktop
      ? 347 : 0.32 * previewRef.current.offsetWidth)
    * active)
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setNotIsDesktop(true)
      } else {
        setNotIsDesktop(false)
      }
    }

    handleResize() // Change screen on first render
    window.addEventListener('resize', handleResize) // Change screen variable on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const redirectTo = () => {
    const card = carouselContainer.items[active]
    dispatch(fetchCurrentContent(card.id))
    navigate('/signIn')
  }

  return (
    <div className="preview" ref={previewContainerRef}>
      <h1 className="preview-title">
        What do you want to watch?
      </h1>
      <div className="preview-list" ref={previewRef}>
        {
            carouselContainer?.items?.map((item, index) => (
              <button
                key={item.id}
                alt="preview-item"
                className="preview-item"
                onClick={() => redirectTo()}
                type="button"
                style={{ left: `${index * (notIsDesktop ? 26 : 32)}${(notIsDesktop ? 'em' : '%')}`, background: `linear-gradient(180deg, rgba(5,5,5,0) 0%, black 100%), url(${item.imageSrc})` }}
              >
                <h1 className="preview-item-label">{item.name}</h1>
              </button>
            ))
        }
      </div>
      <Button classButton="go-to-arrow" icon={backArrow} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

AutoScrollCarousel.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default AutoScrollCarousel
