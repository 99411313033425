import React from 'react'
import PropTypes from 'prop-types'

const Description = ({ title, subtitle, synopsis }) => (
  <div className="description">
    {
      subtitle ? <h5>{subtitle}</h5> : ''
    }
    <h1>{title}</h1>
    <p>
      {synopsis}
    </p>
  </div>
)

Description.propTypes = {
  title: PropTypes.string,
  synopsis: PropTypes.string,
  subtitle: PropTypes.string,
}

Description.defaultProps = {
  title: '',
  synopsis: '',
  subtitle: undefined,
}

export default Description
