/* eslint-disable no-console */
import React from 'react'
import { PasswordReset } from '@cleeng/mediastore-sdk'
import logoIcon from '../../../../assets/images/logo.png'
import backgroundImg from '../../../../assets/images/galeria.jpg'
import '../signIn/signin.scss'
import Button from '../../extraComponents/button/Button'

const ForgotPasswordCleeng = () => (
  <div className="signIn">
    <Button icon={logoIcon} iconAlt="logo" buttonType="logo" />
    <div className="background-wrapper" style={{ background: `linear-gradient(#0000007a, #0000007a), url(${backgroundImg})` }} />

    <PasswordReset onSuccess={() => console.log('success')} />
  </div>
)
export default ForgotPasswordCleeng
