/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { storefrontActions } from '../../../store/storefront/storefront-slice'
import Button from '../../extraComponents/button/Button'
import more from '../../../../assets/images/more.png'

import './card.scss'

const Card = ({
  imageSrc, cardId, cardName, carouselId, carouselType, genres, getMouseOver, touchStart, isStatic,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const redirectTo = () => {
    if (location.pathname.includes('browse')) dispatch(storefrontActions.setScrollHome(window.scrollY))
    navigate(`/details/${cardId}`)
  }

  return (
    <div
      className="card-container"
      onMouseOver={getMouseOver}
      onMouseOut={getMouseOver}
      onClick={() => redirectTo()}
    >
      <div className="card-image" style={touchStart || isStatic ? { position: 'static' } : { position: 'absolute' }}>
        <img src={imageSrc} alt={`${cardName}-${carouselId}`} />
        {
          carouselType !== 'rounded' ? (
            <div className="footer">
              <div className="footer-text">
                <h5>{cardName}</h5>
                <h6>{genres}</h6>
              </div>
              <Button icon={more} title="More Info" iconAlt="more" />
            </div>
          ) : ''
        }
      </div>
    </div>
  )
}

Card.propTypes = {
  cardId: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  genres: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  carouselId: PropTypes.string.isRequired,
  carouselType: PropTypes.string.isRequired,
  getMouseOver: PropTypes.func.isRequired,
  touchStart: PropTypes.bool,
  isStatic: PropTypes.bool,
}

Card.defaultProps = {
  touchStart: false,
  isStatic: false,
}

export default Card
