/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { contentActions } from './content-slice'
import searchJson from '../../../assets/json/search.json'
import recommendationsJson from '../../../assets/json/recommendations.json'
import { fetchResourceDetails, fetchResourceSeason } from '../../services/details/details.service.tsx'

export const fetchCurrentContent = (contentSelected) => {
  return async (dispatch) => {
    const fetchContent = async () => {
      const result = {
        contentInfo: {},
        episodesInfo: {},
        selectedSeason: {},
        recommendations: {},
      }

      result.contentInfo = await fetchResourceDetails(contentSelected)

      if (result.contentInfo.contentType === 'tvshow') {
        // eslint-disable-next-line prefer-destructuring
        result.selectedSeason = result.contentInfo.seasons[0]
        result.episodesInfo = await fetchResourceSeason(
          result.contentInfo.id,
          result.selectedSeason,
        )
      }

      // Fetch recommendations carousels
      const recommended = result?.contentInfo?.genres?.map((item) => (
        recommendationsJson?.find((actualItem) => (
          actualItem?.genre?.toLowerCase() === item?.toLowerCase()
            ? actualItem?.items
            : undefined
        ))
      ))
      // Removing undefined elements
      result.recommendations = recommended?.filter((x) => x !== undefined)
      return result
    }

    const contentDataResult = await fetchContent()
    dispatch(
      contentActions.setContentData({
        content: contentDataResult,
      }),
    )

    return contentDataResult
  }
}

export const fetchSearchResult = (searchTerm) => {
  return async (dispatch) => {
    const fetchSearch = async () => {
      let searchResult = searchJson.find((item) => (
        item?.letter?.toLowerCase() === searchTerm?.charAt(0)?.toLowerCase()
      ))

      const contents = searchResult?.contents?.filter((item) => (
        item?.name?.toLowerCase()?.indexOf(searchTerm?.toLowerCase()) !== -1
      ))
      searchResult = {
        id: '5000',
        name: 'Search',
        type: 'normal',
        items: contents,
      }
      return searchResult
    }

    if (searchTerm) {
      // setSearchTerm(search)
      dispatch(contentActions.setSearchTerm(searchTerm))

      const searchResult = await fetchSearch()

      // setShowResults(result)
      dispatch(
        contentActions.setSearchData({
          content: searchResult,
        }),
      )
    }
  }
}

export const fetchCurrentSeason = (seriesId, seasonNumber) => {
  return async (dispatch) => {
    const fetchSeries = async () => {
      const result = {
        episodesInfo: {},
      }
      result.episodesInfo = await fetchResourceSeason(seriesId, seasonNumber)
      return result
    }

    const seriesResult = await fetchSeries()
    dispatch(
      contentActions.setEpisodesInfo({
        content: seriesResult.episodesInfo,
      }),
    )
  }
}
