/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useRef, useEffect,
} from 'react'
import { PropTypes, instanceOf } from 'prop-types'
import downArrow from '../../../../assets/images/downArrowWhite.png'
import upArrow from '../../../../assets/images/upArrowWhite.png'
import './languageDropdown.scss'

const LanguageDropdown = ({
  contentToShow, maxColumns, valueState, changeHandler,
}) => {
  const [open, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  // Function to rearrange content array
  const reorderData = () => {
    const newOrder = [
      {
        key: 'primero',
        arreglo: [],
      },
      {
        key: 'segundo',
        arreglo: [],
      },
      {
        key: 'tercero',
        arreglo: [],
      },
    ]
    let iterCounter = 0
    const n = contentToShow.length
    const divider = Math.ceil(n / maxColumns)

    // Creates a matrix of maxColumns x n
    while (iterCounter < maxColumns) {
      // eslint-disable-next-line max-len
      newOrder[iterCounter].arreglo.push(contentToShow.slice(divider * iterCounter, divider * (iterCounter + 1)))
      iterCounter += 1
    }
    return newOrder
  }

  const langaugeData = reorderData()

  // Dropdown click
  useEffect(() => {
    // Close menu when user clicks outside
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    // add listener only if there is a dropdown
    if (open) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [open])

  return (
    <ul ref={dropdownRef} className={`dropdownButton dropdownLan ${open ? 'open-dropdown' : ''}`} onClick={() => setIsOpen(!open)}>
      <div className="valueContainer">
        {valueState.name}
      </div>
      <img src={open ? upArrow : downArrow} alt="Open//Close" />
      {
          open
            ? (
              <li className="dropdownBoxContainer">
                {langaugeData.map((col) => (
                  <div key={`${col.key}`} className="dropdown-elements-box">
                    {col.arreglo.map((elem) => (
                      elem.map((language) => (
                        <div key={language.name} onClick={() => changeHandler(language)} className="dropElement">{language.name}</div>
                      ))
                    ))}
                  </div>
                ))}
              </li>
            ) : (
              ''
            )
      }
    </ul>
  )
}

LanguageDropdown.propTypes = {
  contentToShow: instanceOf(Array).isRequired,
  maxColumns: PropTypes.number,
  valueState: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
}

LanguageDropdown.defaultProps = {
  maxColumns: 1,
}

export default LanguageDropdown
