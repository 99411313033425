/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import logoIcon from '../../../../assets/images/logo.png'
import ProfileCard from '../profileCard/ProfileCard'
import './profiles.scss'
import Button from '../../extraComponents/button/Button'
import Loader from '../../extraComponents/loader/Loader'
import { userActions } from '../../../store/user/user-slice'
import { fetchUserData } from '../../../store/user/user-actions'

const Profiles = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const profileData = useSelector((state) => state.user.profiles)
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    dispatch(userActions.setShowNavbar(false))
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserData()).then(() => {
      setShowLoader(false)
    })
  }, [])

  const [canEdit, setCanEdit] = useState(false)

  const changeCanEdit = () => {
    setCanEdit(!canEdit)
  }

  return (
    <>
      { showLoader ? <Loader /> : '' }
      <div className="profiles-container">
        <div className="logo-container">
          <Button icon={logoIcon} iconAlt="logo" buttonType="home" />
        </div>
        <div className="profile-title">
          {canEdit ? t('profiles.manage') : t('profiles.watching')}
        </div>
        <div className="list-container">
          {profileData.map((elem) => (
            <ProfileCard
              key={elem._id}
              id={elem._id}
              isInGrid
              profileName={elem.profileName}
              avatar={elem.avatarSrc}
              editMode={canEdit}
              editLink="/editProfile"
            />
          ))}
          {profileData.length <= 4 ? (<ProfileCard isInGrid isAddCard addLink="/createProfile" />) : null}
        </div>
        <div style={{ width: '100vw', textAlign: 'center' }}>
          <button className="manage-profiles-button" type="button" style={canEdit ? {} : { width: 'auto' }} onClick={() => changeCanEdit()}>
            {canEdit ? t('buttons.done') : t('profiles.manage')}
          </button>
        </div>
      </div>
    </>
  )
}

export default Profiles
