/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth, Login } from '@cleeng/mediastore-sdk'
import backgroundImg from '../../../../assets/images/galeria.jpg'
import logoIcon from '../../../../assets/images/logo.png'
import Button from '../../extraComponents/button/Button'
import './signincleeng.scss'
import { authActions } from '../../../store/auth/auth-slice'
import { fetchUserSubscription } from '../../../store/auth/auth-actions'
import { createCleengAccount } from '../../../services/account/account.service.tsx'

const SignInCleeng = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const isSubscribed = useSelector((state) => state.auth.isSubscribed)
  // const userProfile = useSelector((state) => state.userProfile.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const successLoginHandler = async () => {
    await createCleengAccount()
    Auth.isLogged()
    const [subscription] = await Promise.all([dispatch(fetchUserSubscription())])
    if (!subscription) {
      navigate('/signUp', {
        state: {
          purchaseSubscription: true,
        },
      })
    } else {
      dispatch(authActions.login())
    }
  }

  useEffect(() => {
    if (isLoggedIn && isSubscribed) {
      navigate('/chooseProfile')
    }
  }, [isLoggedIn, isSubscribed])

  return (
    <div className="signIn">
      <Button icon={logoIcon} iconAlt="logo" buttonType="logo" />
      <div className="background-wrapper" style={{ background: `linear-gradient(#0000007a, #0000007a), url(${backgroundImg})` }} />

      <Login
        onSuccess={successLoginHandler}
        onRegisterClick={() => navigate('/signup')}
        onPasswordResetClick={() => navigate('/forgotPassword')}
      />
    </div>
  )
}

export default SignInCleeng
