import { store } from '@cleeng/mediastore-sdk'
import {
  setData as setDataInRedux,
  removeData as removeDataFromRedux,
} from '../appConfig'

// Replicate Cleeng /src/util/appConfigHelper.js
const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('CLEENG_LS', 'yes')
    if (localStorage.getItem('CLEENG_LS') === 'yes') {
      localStorage.removeItem('CLEENG_LS')
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const getData = (name) => (isLocalStorageAvailable()
  ? localStorage.getItem(name)
  : store.getState().appConfig[name])

export const setData = (name, value) => (isLocalStorageAvailable()
  ? localStorage.setItem(name, value)
  : store.dispatch(setDataInRedux({ name, value })))

export const removeData = (name) => (isLocalStorageAvailable()
  ? localStorage.removeItem(name)
  : store.dispatch(removeDataFromRedux({ name })))

export const sendMessage = (msg) => {
  if (window.opener) {
    window.opener.postMessage(msg, '*')
  } else if (window.top) {
    window.top.postMessage(msg, '*')
  }
}

export const isHosted = () => getData('CLEENG_HOSTED') && getData('CLEENG_HOSTED') === 'true'
