import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CheckoutConsents, Purchase, Register } from '@cleeng/mediastore-sdk'
import backgroundImg from '../../../../assets/images/galeria.jpg'
import Button from '../../extraComponents/button/Button'
import logoIcon from '../../../../assets/images/logo.png'
import '../signIn/signincleeng.scss'
import ChangePlan from '../../settingsRelated/changePlan/ChangePlan'
import CleengLoader from '../../extraComponents/loader/CleengLoader'
import { authActions } from '../../../store/auth/auth-slice'
import { createCleengAccount } from '../../../services/account/account.service.tsx'

const SignUpSteps = {
  REGISTER: {
    stepNumber: 0,
    nextStep: 1,
  },
  CONSENTS: {
    stepNumber: 1,
    nextStep: 2,
  },
  CHANGEPLAN: {
    stepNumber: 2,
    nextStep: 3,
  },
  PURCHASE: {
    stepNumber: 3,
  },
}

const SignUpCleeng = () => {
  const [showLoader, setShowLoader] = useState(false)
  const [actualStep, setActualStep] = useState(0)
  const [offerId, setOfferId] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (actualStep === SignUpSteps.CHANGEPLAN.stepNumber) {
      setShowLoader(false)
    } else {
      // check if cleeng loader is on screen
      const checkLoader = setInterval(() => {
        if (!document.getElementsByClassName('msd__spinner')[0]) {
          clearInterval(checkLoader)
          setShowLoader(false)
        }
      }, 500)
    }
  }, [actualStep])

  const onSuccesfulSignUp = () => {
    dispatch(authActions.login())
    dispatch(
      authActions.setSubscription({
        content: true,
      }),
    )
    navigate('/chooseProfile')
  }

  const changeStep = (stepNumber) => {
    // eslint-disable-next-line no-console
    if (SignUpSteps.REGISTER.stepNumber) createCleengAccount()
    setActualStep(stepNumber)
    setShowLoader(true)
  }

  useEffect(() => {
    const { state } = location
    if (state?.purchaseSubscription) {
      changeStep(SignUpSteps.CONSENTS.nextStep)
    }
  }, [])

  return (
    <div className="signIn" style={{ background: `linear-gradient(#0000007a, #0000007a), url(${backgroundImg})` }}>
      { actualStep === 2 ? null : <Button icon={logoIcon} iconAlt="logo" buttonType="logo" /> }

      { showLoader ? <CleengLoader /> : ''}

      {
        actualStep === SignUpSteps.REGISTER.stepNumber
          ? (
            <Register
              onSuccess={() => changeStep(SignUpSteps.REGISTER.nextStep)}
              onHaveAccountClick={() => navigate('/signIn')}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.CONSENTS.stepNumber
          ? (
            <CheckoutConsents
              onSuccess={() => changeStep(SignUpSteps.CONSENTS.nextStep)}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.CHANGEPLAN.stepNumber
          ? (
            <ChangePlan
              isSignUp
              setOfferId={setOfferId}
              onClick={() => changeStep(SignUpSteps.CHANGEPLAN.nextStep)}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.PURCHASE.stepNumber
          ? (
            <Purchase
              offerId={offerId}
              onSuccess={onSuccesfulSignUp}
            />
          )
          : ''
      }
    </div>
  )
}

export default SignUpCleeng
