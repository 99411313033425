import React from 'react'
import PropTypes from 'prop-types'
import logoIcon from '../../../../assets/images/logo.png'

const PlayerBottom = ({ title, subtitle, showControllers }) => (
  <div className={`player-bottom ${showControllers ? 'show' : 'hide'}`}>
    <div className="logo" style={{ backgroundImage: `url(${logoIcon})` }} />
    <h1>{title}</h1>
    { subtitle ? <h2>{subtitle}</h2> : '' }
  </div>
)

PlayerBottom.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showControllers: PropTypes.bool,
}

PlayerBottom.defaultProps = {
  subtitle: undefined,
  showControllers: true,
}

export default PlayerBottom
