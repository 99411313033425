/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './changePlan.scss'
import plansJson from '../../../../assets/json/plans.json'
import Popup from '../../extraComponents/popUp/Popup'
import backgroundImg from '../../../../assets/images/galeria.jpg'
import { userActions } from '../../../store/user/user-slice'
import logoIcon from '../../../../assets/images/logo.png'

const ChangePlan = ({ isSignUp, setOfferId, onClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [active, setActive] = React.useState(1)
  const [signUpRedirect, setSignUpRedirect] = React.useState(false)
  const [avaible, setAvaible] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const testActualPlan = 1

  useEffect(() => {
    if (active === testActualPlan) {
      setAvaible(false)
    } else {
      setAvaible(true)
    }
  }, [active])

  useEffect(() => {
    if (isSignUp) {
      setSignUpRedirect(isSignUp)
      dispatch(userActions.setShowNavbar(!isSignUp))
    } else {
      dispatch(userActions.setShowNavbar(true))
    }
  }, [dispatch, location])

  const handleChangePlan = (id, index) => {
    setOfferId(id)
    setActive(index)
  }

  const handleOkPopup = () => {
    setVisible(false)
    navigate('/chooseProfile')
  }

  const handleCancelPopup = () => {
    setVisible(false)
  }

  return (
    <div className={signUpRedirect ? 'plan-change-container' : ''}>
      {signUpRedirect ? <div className="wrapper" style={{ background: `url(${backgroundImg})` }} /> : ''}
      <div className={signUpRedirect ? 'plan-change-sign-up' : 'plan-change'}>
        <h1>
          {signUpRedirect ? t('plan.subscribe') : t('plan.change')}
          {signUpRedirect ? <img src={logoIcon} alt="logo-icon" /> : null}
        </h1>
        { plansJson?.map((item, index) => (
          <div tabIndex={0} onKeyDown={() => {}} onClick={() => handleChangePlan(item.id, index)} key={item.id} className={active === index ? 'plan-item-active' : 'plan-item'} role="button">
            <div className="plan-title">
              {signUpRedirect ? null : <div className={index === testActualPlan ? 'active-current-title' : 'current-title'}>{t('plan.current')}</div>}
              <div className="title">{item.name}</div>
            </div>
            <div className="plan-info">{item.description}</div>
            <div className="plan-price">{item.cost}</div>
          </div>
        ))}
        {
          signUpRedirect
            ? (
              <div className="plan-change-policy">
                {t('plan.paymentInfo')}
                {' '}
                {plansJson[active].cost.includes('year') ? (new Date((new Date()).setFullYear((new Date()).getFullYear() + 1))).toLocaleDateString() : (new Date((new Date()).setMonth((new Date()).getMonth() + 1))).toLocaleDateString()}
              </div>
            )
            : <div className="plan-change-policy">{t('plan.disclaimer')}</div>
        }
        <div className="buttons-container">
          <button className="go-back-button" onClick={signUpRedirect ? () => navigate('/signUp') : () => navigate('/settings')} type="button">{t('buttons.back')}</button>
          {
          signUpRedirect
            ? <button className="active-confirmation-button" onClick={onClick} type="button">{t('buttons.continue')}</button>
            : <button className={avaible ? 'active-confirmation-button' : 'confirmation-button'} onClick={() => setVisible(true)} type="button">{t('buttons.continue')}</button>
          }
        </div>
      </div>
      {visible
        && (
        <Popup
          buttonOk={{ text: 'Yes', func: handleOkPopup }}
          buttonCancel={{ text: 'No', func: handleCancelPopup }}
          buttonXfunc={handleCancelPopup}
          content={signUpRedirect ? { title: t('popup.changePlan.title'), info: `${t('popup.changePlan.subtitle1')}${plansJson[active].name}? ${t('popup.changePlan.subtitle2')} ${plansJson[active].cost}` } : { title: t('popup.subscribe.title'), info: t('popup.subscribe.subtitle') }}
        />
        )}
    </div>
  )
}

ChangePlan.propTypes = {
  isSignUp: PropTypes.bool,
  setOfferId: PropTypes.func,
  onClick: PropTypes.func,
}

ChangePlan.defaultProps = {
  isSignUp: false,
  setOfferId: () => {},
  onClick: () => {},
}

export default ChangePlan
