import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../store/user/user-slice'
import GridCarousel from '../carouselRelated/gridCarousel/GridCarousel'
import './search.scss'

const Search = () => {
  const searchTerm = useSelector((state) => state.content.searchTerm)
  const showResults = useSelector((state) => state.content.showResults)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.setShowNavbar(true))
  }, [dispatch])

  return (
    <div className="search-page">
      <h1>
        Results for
        { ' ' }
        <span>
          &quot;
          { searchTerm }
          &quot;
        </span>
      </h1>
      <GridCarousel carousel={showResults} />
    </div>
  )
}

export default Search
