import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from './Card'
import Arrows from './Arrows'
import './carousel.scss'

const Carousel = ({ carousel }) => {
  const carouselRef = useRef()
  const [styles, setStyles] = useState({ left: undefined })
  const [mouseOver, setMouseOver] = useState(false)
  const [touchStart, setTouchStart] = useState(false)

  // If the mouse is over the card, change zindex of carousel
  const getMouseOver = () => {
    setMouseOver(!mouseOver)
    setStyles({
      ...styles,
      zIndex: mouseOver ? 0 : 2,
    })
  }

  // If the touch starts, then user is on mobile/tablet
  const allowScroll = (isDesktop) => {
    setStyles({
      ...styles,
      overflowX: isDesktop ? undefined : 'scroll',
    })
    setTouchStart(!isDesktop)
  }

  // handle scroll on carousels
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth <= 1180) {
        allowScroll(false)
      } else {
        carouselRef.current.scrollLeft = 0
        allowScroll(true)
      }
    }

    handleResize() // Check scroll on first render
    window.addEventListener('resize', handleResize) // Change scroll on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="carousel-container">
      <h1>{carousel.name}</h1>
      <div className={`carousel ${carousel.type}`}>

        <Arrows
          carouselType={carousel.type}
          carouselRef={carouselRef}
          setStyles={setStyles}
          carouselLength={carousel.items.length % 5 > 1
            ? (carousel.items.length % 5) - 1
            : carousel.items.length % 5}
          touchStart={touchStart}
        />

        <div
          className="slider"
          style={{ ...styles }}
          ref={carouselRef}
        >
          {carousel.items.map((card) => (
            <Card
              cardId={card.id}
              // eslint-disable-next-line no-nested-ternary
              imageSrc={carousel.type === 'rounded'
                ? card.imageSrcCarousel['1x1']
                : carousel.type === 'bigger'
                  ? card.imageSrcCarousel['2x3']
                  : card.imageSrcCarousel['16x9']}
              cardName={card.name}
              carouselType={carousel.type}
              carouselId={carousel.id}
              genres={card.genres.join(' • ')}
              getMouseOver={getMouseOver}
              key={`${carousel.id}-${card.id}`}
              touchStart={touchStart}
            />
          ))}
        </div>

      </div>
    </div>
  )
}

Carousel.propTypes = {
  carousel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        genres: PropTypes.arrayOf(PropTypes.string).isRequired,
        imageSrcCarousel: PropTypes.shape({
          layout: PropTypes.string,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,

}

export default Carousel
