/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { Player } from 'bitmovin-player/modules/bitmovinplayer-core'
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin'
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer'
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls'
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash'
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr'
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml'
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts'
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4'
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles'
import SubtitlesCEA608Module from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608'
import DRMModule from 'bitmovin-player/modules/bitmovinplayer-drm'
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill'
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style'
import ContainerWebModule from 'bitmovin-player/modules/bitmovinplayer-container-webm'
import CrypoModule from 'bitmovin-player/modules/bitmovinplayer-crypto'
import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native'
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui'
import 'bitmovin-player/bitmovinplayer-ui.css'

const BitmovinPlayer = () => {
  const playerDiv = React.createRef()
  const [state, setState] = useState(
    { player: null },
  )
  const [player, setPlayer] = useState()

  const playerConfig = {
    key: 'DD940CAA-D271-4519-840C-5D86F866A3F6',
    buffer: {
      video: {
        forwardduration: 30,
        backwardduration: 10,
      },
      audio: {
        forwardduration: 30,
        backwardduration: 10,
      },
    },
    playback: {
      autoplay: true,
    },
  }

  const setupPlayer = () => {
    Player.addModule(EngineBitmovinModule)
    Player.addModule(MseRendererModule)
    Player.addModule(HlsModule)
    Player.addModule(XmlModule)
    Player.addModule(DashModule)
    Player.addModule(AbrModule)
    Player.addModule(ContainerTSModule)
    Player.addModule(ContainerMp4Module)
    Player.addModule(SubtitlesModule)
    Player.addModule(SubtitlesCEA608Module)
    Player.addModule(PolyfillModule)
    Player.addModule(StyleModule)
    Player.addModule(DRMModule)
    Player.addModule(ContainerWebModule)
    Player.addModule(CrypoModule)
    Player.addModule(EngineNativeModule)

    const player2 = new Player(playerDiv.current, playerConfig)
    UIFactory.buildDefaultUI(player2)
    setPlayer(player2)
  }

  useEffect(() => {
    setupPlayer()
  }, [])

  const changeSrc = (playerSource) => {
    player.load(playerSource).then(() => {
      setState({
        ...state,
        player,
      })
      player.play()
      console.log('Successfully loaded source')
    }, () => {
      console.log('Error while loading source')
    })
  }

  useEffect(() => {
    if (player) {
      changeSrc({
        dash: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
        hls: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
        poster: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e57f8b1c-1c99-4f62-b6e7-9f3e3f9d958b/df4vm1y-98b6edfc-3f4b-4393-811b-e6f3263be404.png/v1/fill/w_1000,h_750,q_80,strp/poster_by_andrea_smiler_df4vm1y-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzUwIiwicGF0aCI6IlwvZlwvZTU3ZjhiMWMtMWM5OS00ZjYyLWI2ZTctOWYzZTNmOWQ5NThiXC9kZjR2bTF5LTk4YjZlZGZjLTNmNGItNDM5My04MTFiLWU2ZjMyNjNiZTQwNC5wbmciLCJ3aWR0aCI6Ijw9MTAwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.tM8glx2ik8YG__P3WAA3AbyA9uFhkesmiZGq8idedcs',
      })
    }
  }, [player])

  return <div id="player" ref={playerDiv} />
}

export default BitmovinPlayer
