/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import searchIcon from '../../../../assets/images/searchWhite.png'
import { fetchSearchResult } from '../../../store/content/content-actions'
import './searchBar.scss'

const SearchBar = ({
  shouldBeOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [display, setDisplay] = useState(shouldBeOpen ? 'block' : 'none')
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const searchRef = useRef()
  const dispatch = useDispatch()

  const handleInputChange = (ev) => {
    const search = ev.target.value

    if (search.length === 1) {
      dispatch(fetchSearchResult(search))
      navigate('/search')
    } else if (search.length > 0) {
      dispatch(fetchSearchResult(search))
    } else {
      navigate('/browse')
    }
  }

  const changeOpenState = () => {
    setIsOpen(!isOpen)
    setTimeout(() => {
      setDisplay(!isOpen ? 'block' : 'none')
      searchRef.current.focus()
    }, 250)
  }

  const handleInputBlur = () => {
    changeOpenState()
  }

  return (
    <div className="search-container">
      <img className="search-icon" onClick={() => changeOpenState()} src={searchIcon} alt="Search" />
      <input
        className={`search-input ${isOpen ? '' : 'closing'}`}
        type="text"
        style={{ display }}
        onChange={(e) => handleInputChange(e)}
        onBlur={handleInputBlur}
        ref={searchRef}
        placeholder={t('search.action')}
      />
    </div>
  )
}

SearchBar.propTypes = {
  shouldBeOpen: PropTypes.bool,
}

SearchBar.defaultProps = {
  shouldBeOpen: false,
}

export default SearchBar
