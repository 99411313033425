/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProfileCard from '../profileCard/ProfileCard'
import './createProfile.scss'
import avatarsJson from '../../../../assets/json/avatars.json'
import { userActions } from '../../../store/user/user-slice'
import { AddContactRequestModel } from '../../../services/account/model/add-profile.request.model.tsx'
import { addNewProfile } from '../../../services/account/account.service.tsx'
import Button from '../../extraComponents/button/Button'
import logoIcon from '../../../../assets/images/logo.png'

const CreateProfile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [isKid, setIsKid] = useState(false)
  const [hasAutoPlay, setHasAutoplay] = useState(false)

  useEffect(() => {
    dispatch(userActions.setShowNavbar(false))
  }, [dispatch])

  const [profileUpdatedInfo, setProfileUpdatedInfo] = useState({
    name: '',
    avatar: '',
  })

  const navigate = useNavigate()
  const redirectTo = (text) => {
    navigate(`/${text}`)
  }

  const handleNameChange = (ev) => {
    setProfileUpdatedInfo({
      ...profileUpdatedInfo,
      name: ev.target.value,
    })
  }

  // function to generate random integer between min and max
  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  // function to get random avatar
  const getRandomAvatar = () => {
    const randomSectionIndex = getRandomInt(0, avatarsJson.length)
    const randomAvatarIndex = getRandomInt(0, avatarsJson[randomSectionIndex].icons.length)
    return avatarsJson[randomSectionIndex].icons[randomAvatarIndex].imageSrc
  }

  const infoIsValid = () => profileUpdatedInfo.name !== '' && profileUpdatedInfo.avatar !== ''

  // create profile
  const createProfile = async () => {
    const request = new AddContactRequestModel()
    request.profileName = profileUpdatedInfo.name
    request.avatarSrc = profileUpdatedInfo.avatar
    request.maturityRating = isKid ? 'G' : 'NC-17'
    request.hasAutoPlay = hasAutoPlay
    request.prefLang = 'en'

    await addNewProfile(localStorage.getItem('CLEENG_CUSTOMER_ID'), request)
    redirectTo('chooseProfile')
  }

  const handleAutoplayChange = (event) => {
    setHasAutoplay(event.target.checked)
  }

  const handleKidProfile = (event) => {
    setIsKid(event.target.checked)
  }

  // set avatar in profileUpdatedInfo.avatar on load
  useEffect(() => {
    setProfileUpdatedInfo({
      ...profileUpdatedInfo,
      avatar: getRandomAvatar(),
    })
  }, [])

  return (
    <>
      <div className="logo-container">
        <Button icon={logoIcon} iconAlt="logo" buttonType="home" />
      </div>
      <div className="createProfile">
        <div className="main-container">
          <div className="title-account">
            {t('profiles.create')}
          </div>
          <div className="data-container">
            <div className="photo-container">
              <div className="avatar-container">
                <ProfileCard
                  isInGrid={false}
                  editMode={false}
                  avatar={profileUpdatedInfo.avatar}
                  profileName=""
                  editLink=""
                />
              </div>
            </div>
            <div className="settings-container">
              <input className="profile-name-input" name="name" type="input" placeholder={t('inputs.name')} value={profileUpdatedInfo.name} onChange={(e) => handleNameChange(e)} />
              <div className="container-checkbox">
                <label className="checkbox-kid-label" htmlFor="checkbox-kid-label" value="Text Message (SMS)">
                  <input type="checkbox" id="checkbox-kid-label" name="isKid" className="checkbox-input" onChange={handleKidProfile} />
                  <span className="checkbox-text" />
                  {t('profiles.kid')}
                </label>
              </div>
              <div className="container-checkbox">
                <label className="checkbox-autoplay-label" htmlFor="checkbox-autoplay-label" value="Text Message (SMS)">
                  <input type="checkbox" id="checkbox-autoplay-label" name="hasAutoplay" className="checkbox-input" onChange={handleAutoplayChange} />
                  <span className="checkbox-text" />
                  {t('profiles.autoplay')}
                </label>
              </div>
            </div>
          </div>
          <div className="buttons-container">
            <button type="button" className="btn-save" disabled={!infoIsValid()} onClick={() => createProfile()}><strong>{t('buttons.save')}</strong></button>
            <button type="button" className="btn-cancel" onClick={() => redirectTo('chooseProfile')}><strong>{t('buttons.cancel')}</strong></button>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateProfile
