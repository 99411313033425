/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import logoIcon from '../../../../assets/images/logo.png'
import './banner.scss'
import backArrow from '../../../../assets/images/backArrow.png'
import backgroundGif from '../../../../assets/images/background.gif'
import Button from '../../extraComponents/button/Button'

const Banner = ({ buttonFunc }) => {
  const { t } = useTranslation('common')
  return (
    <div className="banner" style={{ background: `linear-gradient( 0deg, rgb(114, 57, 213, 0.7), rgb(213, 57, 156, 0.0) 60%), linear-gradient(#0000007a, black), url(${backgroundGif})` }}>
      <img className="logo-img" src={logoIcon} alt="logo icon" />
      <div className="banner-title">{t('welcome.title')}</div>
      <button type="button" className="banner-btn" onClick={buttonFunc}>{t('subscribe.title')}</button>
      <div className="banner-links">
        <a href="/signUp">{t('subscribe.monthly')}</a>
        {' '}
        |
        {' '}
        <a href="/signUp">{t('subscribe.yearly')}</a>
      </div>
      <Button classButton="go-to-arrow" icon={backArrow} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

Banner.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default Banner
