/* eslint-disable import/no-duplicates */
import React from 'react'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import i18next from './i18n'
import App from './App'
import { persistor, store } from './store'

const root = (
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </I18nextProvider>
)
ReactDOM.render(root, document.getElementById('root'))
