import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Description from './Description'
import Button from '../extraComponents/button/Button'
import Tags from './Tags'
import playIcon from '../../../assets/images/playIcon.png'
import watchlist from '../../../assets/images/watchlist.png'
import './details.scss'
import TabSelector from '../extraComponents/optionsSelector/TabSelector'
import { userActions } from '../../store/user/user-slice'
import { fetchCurrentContent } from '../../store/content/content-actions'
import Loader from '../extraComponents/loader/Loader'

const DetailsScreen = () => {
  const dispatch = useDispatch()
  const currentContentInfo = useSelector((state) => state.content.currentContentInfo)
  const [isSquareAspect, setIsSquareAspect] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const navigate = useNavigate()

  const location = useLocation()
  const contentId = location.pathname.split('/details/')[1]

  useEffect(async () => {
    dispatch(userActions.setShowNavbar(true))
    if (currentContentInfo?.id !== contentId) {
      dispatch(fetchCurrentContent(contentId)).then(() => {
        setShowLoader(false)
      })
    } else {
      setShowLoader(false)
    }
  }, [])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 950 && window.screen.availHeight > 500) {
        setIsSquareAspect(true)
      } else {
        setIsSquareAspect(false)
      }
    }

    handleResize() // Change ia on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
      currentContentInfo && !showLoader
        ? (
          <div className="details-screen">
            <div
              className="details-banner"
              style={{
                background: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),
                        linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%),
                        url(${isSquareAspect ? currentContentInfo.imageSrcBanner['1x1'] : currentContentInfo.imageSrcBanner['16x9']})`,
              }}
            >
              <div className="container">
                <Tags tagsList={[currentContentInfo?.ageRestriction,
                  currentContentInfo?.duration,
                ].concat(currentContentInfo?.genres)}
                />
                <Description
                  title={currentContentInfo?.name}
                  synopsis={currentContentInfo?.synopsis}
                />
                <div className="user-actions">
                  <Button classButton="play-button" text="Play" icon={playIcon} onClick={() => navigate(`/player/${currentContentInfo.id}`)} />
                  <Button icon={watchlist} />
                </div>
              </div>
            </div>
            <TabSelector
              tabs={
                currentContentInfo?.contentType === 'tvshow'
                  ? ['Episodes', 'Recommendations', 'Details']
                  : ['Recommendations', 'Details']
              }
              contentId={contentId}
              currentContentInfo={currentContentInfo}
            />
          </div>
        )
        : <Loader />
    }
    </>
  )
}
export default DetailsScreen
