import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import backArrow from '../../../../assets/images/backArrow.png'
import AvatarsCards from './avatarsCards'
import ButtonArrow from './ButtonArrow'
import AvatarsImages from './AvatarsImages'
import './avatarsManage.scss'

const AvatarsManage = ({ setNewIcon, setShowAvatars }) => {
  const { t } = useTranslation('common')
  const profileSelected = useSelector((state) => state.user.profileSelected)

  return (
    <div className="avatars">
      <div className="avatars-header">
        <ButtonArrow id="back arrow" className="back-arrow" imgSrc={backArrow} />

        <div className="options">
          <div className="edit-profile">{t('profiles.edit')}</div>
          <div className="edit-profile">{t('profiles.icon.choose')}</div>
        </div>

        <div className="profile">
          <div className="user-name">{profileSelected?.profileName}</div>
          <AvatarsImages className="user-avatar" imgSrc={profileSelected?.avatarSrc} altImg="User Icon" />
        </div>
      </div>

      <div className="avatars-content">
        <AvatarsCards setNewIcon={setNewIcon} setShowAvatars={setShowAvatars} />
      </div>
    </div>
  )
}

AvatarsManage.propTypes = {
  setNewIcon: PropTypes.func,
  setShowAvatars: PropTypes.func,
}

AvatarsManage.defaultProps = {
  setNewIcon: () => {},
  setShowAvatars: () => {},
}

export default AvatarsManage
