import React from 'react'
import PropTypes from 'prop-types'
import carouselsJson from '../../../../assets/json/carousels.json'
import Carousel from './Carousel'

const Carousels = ({ carousels, recommended }) => (
  <div className={`carousels ${recommended ? 'recommended' : ''}`}>
    {carousels.map((carousel) => (
      carousel.type !== 'banner' ? <Carousel carousel={carousel} key={`carousel-${carousel.id}`} /> : ''
    ))}
  </div>
)

Carousels.propTypes = {
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          genres: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
      ),
    }),
  ),
  recommended: PropTypes.bool,
}

Carousels.defaultProps = {
  carousels: carouselsJson,
  recommended: false,
}

export default Carousels
