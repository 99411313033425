import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import plansJson from '../../../../assets/json/plans.json'
import Button from '../../extraComponents/button/Button'
import Plan from './Plan'
import backArrow from '../../../../assets/images/backArrow.png'

const Plans = ({ buttonFunc }) => {
  const { t } = useTranslation('common')
  return (
    <div className="plans-section">
      <h1>{t('plan.title')}</h1>
      <div className="plans-container">
        { plansJson?.map((item) => (
          <Plan key={item.id} plan={item} />
        ))}

      </div>
      <Button classButton="go-to-arrow" icon={backArrow} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

Plans.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default Plans
