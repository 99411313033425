/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import playIcon from '../../../assets/images/purple-play.png'

const Episode = ({ episode }) => {
  const navigate = useNavigate()

  return (
    <div className="episode">
      <div className="episode-overlay" onClick={() => navigate(`/player/${episode.id}`)}>
        <div className="overlay" />
        <img src={playIcon} alt="play icon" />
        <div className="episode-preview" style={{ backgroundImage: `url(${episode?.imageSrcPreview['16x9']})` }} />
      </div>
      <div className="episode-info">
        <h1>{ episode.name }</h1>
        <div className="episode-subheader">
          <h2>{ `S${episode.seasonNumber} : E${episode.episodeNumber}` }</h2>
          <h2>•</h2>
          <h2>{ episode.duration }</h2>
        </div>
        <p>
          { episode.synopsis }
        </p>
      </div>
    </div>
  )
}

Episode.propTypes = {
  episode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cast: PropTypes.arrayOf(PropTypes.string).isRequired,
    ageRestriction: PropTypes.string.isRequired,
    imageSrcPreview: PropTypes.shape({
      layout: PropTypes.string,
      '16x9': PropTypes.string,
    }).isRequired,
    duration: PropTypes.string.isRequired,
    synopsis: PropTypes.string.isRequired,
    seasonNumber: PropTypes.number.isRequired,
    episodeNumber: PropTypes.number.isRequired,
  }).isRequired,
}

export default Episode
