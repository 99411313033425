import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import './loader.scss'

const Loader = ({ isProfileSelected }) => {
  const profileSelected = useSelector((state) => state.user.profileSelected)
  return (
    <div className="sk-chase-container">
      { isProfileSelected ? <div className="profileSelected" style={{ backgroundImage: `url(${profileSelected.avatarSrc})` }} /> : '' }
      <div className={`lds-ring ${isProfileSelected ? '' : 'normal'}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

Loader.propTypes = {
  isProfileSelected: PropTypes.bool,
}

Loader.defaultProps = {
  isProfileSelected: false,
}

export default Loader
