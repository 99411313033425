import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Hero from '../../carouselRelated/hero/Hero'
import Carousels from '../../carouselRelated/carousel/Carousels'
import { userActions } from '../../../store/user/user-slice'
import { storefrontActions } from '../../../store/storefront/storefront-slice'
import Loader from '../../extraComponents/loader/Loader'
import { fetchStorefront } from '../../../store/storefront/storefront-actions'

const VODHome = () => {
  const profileSelected = useSelector((state) => state.user.profileSelected)
  const carouselsContainers = useSelector((state) => state.storefront.storefrontCarousels)
  const heroContainer = useSelector((state) => state.storefront.storefrontHero)
  const scrollPosition = useSelector((state) => state.storefront.scrollHome)

  const [showLoader, setShowLoader] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.setShowNavbar(true))
    window.scrollTo(0, scrollPosition)
    dispatch(storefrontActions.setScrollHome(0))
  }, [dispatch])

  const fetchResources = async () => {
    dispatch(fetchStorefront(profileSelected.prefLang.ISO)).then(() => {
      setShowLoader(false)
    })
  }

  useEffect(() => {
    if (!carouselsContainers) {
      // fetch only for deeplinking
      fetchResources()
    } else {
      setShowLoader(false)
    }
  }, [])

  return (
    <div className="vod-home">
      { showLoader ? <Loader /> : '' }
      { heroContainer ? <Hero carousel={heroContainer} /> : '' }
      { carouselsContainers ? <Carousels carousels={carouselsContainers} /> : ''}
    </div>
  )
}

export default VODHome
