import React from 'react'
import PropTypes from 'prop-types'
import Card from '../carousel/Card'
import './gridCarousel.scss'

const GridCarousel = ({ carousel }) => (
  <div className="grid-carousel-container">
    {carousel.items.map((card) => (
      <Card
        cardId={card.id}
        imageSrc={card.imageSrc}
        cardName={card.name}
        carouselType={carousel.type}
        carouselId={carousel.id}
        genres={card.genres.join(' • ')}
        key={carousel.id + card.name}
        isStatic
      />
    ))}
  </div>
)

GridCarousel.propTypes = {
  carousel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        imageSrc: PropTypes.string.isRequired,
        genres: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,

}

export default GridCarousel
