import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './plan.scss'

const Plan = ({ plan }) => {
  const { t } = useTranslation('common')
  return (
    <div className={`plan-container-border  ${plan.priority === 1 ? 'best' : ''}`} id={plan.id}>
      <div className="plan-container">
        <div className="plan-information">
          <p className="plan-title">{plan.name}</p>
          <h1 className="plan-cost">{plan.cost}</h1>
          <p className="plan-description">
            {plan.description}
          </p>
        </div>
        <button type="button" className={`${plan.priority === 1 ? 'best' : ''}`} onClick={() => {}}>{t('plan.get')}</button>
      </div>
    </div>
  )
}

Plan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    priority: PropTypes.number,
    description: PropTypes.string.isRequired,
    cost: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

Plan.defaultProps = {
  plan: {
    priority: 0,
  },
}

export default Plan
