/* eslint-disable no-console */
/* eslint-disable no-debugger */
import React, {
  useCallback, useState, useEffect,
} from 'react'
import { useDispatch, Provider, useSelector } from 'react-redux'
import { MyAccount, store } from '@cleeng/mediastore-sdk'
import backgroundImg from '../../../../assets/images/galeria.jpg'
import logoIcon from '../../../../assets/images/logo.png'
import Button from '../../extraComponents/button/Button'
import { authActions } from '../../../store/auth/auth-slice'
import { userActions } from '../../../store/user/user-slice'
import '../../accountRelated/signIn/signincleeng.scss'

const SettingCleeng = () => {
  const [isCleeng, setMyCleeng] = useState(useSelector((state) => state.auth.isLoggedIn))

  const dispatch = useDispatch()

  useEffect(() => {
    if (isCleeng === true) {
      dispatch(userActions.setShowNavbar(true))
      dispatch(authActions.login())
    } else {
      dispatch(userActions.setShowNavbar(false))
      dispatch(authActions.logout())
    }
  }, [isCleeng])

  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      if (mutation?.target?.className?.includes('msd__account__card')) {
        document.getElementsByClassName('msd__account__card')[0].style.display = 'none'
        document.getElementsByClassName('msd__section-header')[0].style.display = 'none'
      }
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((element) => {
          if (element?.className?.includes('msd__account-wrapper')) {
            setMyCleeng(true)
          }
          if (element?.className?.includes('msd__auth-wrapper')) {
            setMyCleeng(false)
          }
        })
      }
    })
  })
  const onRefChange = useCallback((node) => {
    // call `observe()` on that MutationObserver instance,
    // passing it the element to observe, and the options object
    if (node) {
      observer.observe(node, { childList: true, attributes: true, subtree: true })
    }
    return () => { observer.disconnect() }
  }, []) // adjust deps
  return (
    <div className={isCleeng ? '' : 'signIn'} ref={onRefChange}>
      {isCleeng ? null : <Button icon={logoIcon} iconAlt="logo" buttonType="logo" />}
      {isCleeng ? null : <div className="background-wrapper" style={{ background: `linear-gradient(#0000007a, #0000007a), url(${backgroundImg})` }} />}
      <Provider store={store}>
        <MyAccount />
      </Provider>
    </div>

  )
}

export default SettingCleeng
