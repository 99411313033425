/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { fetchProfileData } from '../../../store/user/user-actions'
import plusIcon from '../../../../assets/images/plus.png'
import pencilIcon from '../../../../assets/images/pencilIcon.png'
import './profileCard.scss'
import AvatarsManage from '../avatars/avatarsManage'
import { deepLinkActions } from '../../../store/deepLink/deep-link-slice'
import { fetchStorefront } from '../../../store/storefront/storefront-actions'
import Loader from '../../extraComponents/loader/Loader'

const ProfileCard = ({
  id,
  isInGrid,
  profileName,
  avatar,
  isAddCard,
  editMode,
  addLink,
  editLink,
  displayName,
  profileUpdatedInfo,
  setProfileUpdatedInfo,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showAvatars, setShowAvatars] = useState(false)
  const [newIcon, setNewIcon] = useState(avatar)
  const [loader, setShowLoader] = useState(false)

  const nextAction = useSelector((state) => state.deeplink.nextAction)

  const { t } = useTranslation('common')

  const navigateTo = () => {
    if (profileUpdatedInfo) {
      window.scrollTo(0, 0)
      setShowAvatars(true)
    } else if (id) {
      dispatch(fetchProfileData(id)).then((profileSelected) => {
        setShowLoader(true)
        if (editMode) {
          navigate(editLink)
        } else if (nextAction !== '') {
          navigate(nextAction)
          dispatch(deepLinkActions.setNextAction({
            content: '',
          }))
        } else {
          dispatch(fetchStorefront(profileSelected.prefLang.ISO)).then(() => {
            navigate('/browse')
          })
        }
      })
    }
  }

  useEffect(() => {
    if (profileUpdatedInfo) {
      setProfileUpdatedInfo({
        ...profileUpdatedInfo,
        avatar: newIcon,
      })
    }
  }, [newIcon])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { loader ? <Loader isProfileSelected /> : ''}
      {
      isAddCard ? (
        <Link to={addLink}>
          <div className={`plus-icon-button-container ${isInGrid ? 'gridElement' : ''}`}>
            <div className="profile-avatar">
              <div className="plus-icon-button">
                <img className="plus-icon" src={plusIcon} alt="avatar" />
              </div>

            </div>
            <div className="profile-name plus-icon-button-text">{t('profiles.add')}</div>
          </div>
        </Link>
      ) : ''
      }
      { showAvatars
        ? <AvatarsManage setNewIcon={setNewIcon} setShowAvatars={setShowAvatars} /> : ''}
      {
        !isAddCard ? (
          <div key={profileName} className={`${isInGrid ? 'gridElement' : ''}`} onClick={() => navigateTo()}>
            <div className="image-container">
              <img className={`profile-avatar ${editMode ? 'faded-avatar' : ''}`} src={avatar} alt="avatar" />
              {editMode ? (
                <img className="pencil-icon" src={pencilIcon} alt="Edit" />
              ) : null}
            </div>
            { displayName ? <div className="profile-name">{profileName}</div> : '' }
          </div>
        ) : ''
      }
    </>

  )
}

ProfileCard.propTypes = {
  id: PropTypes.string,
  isInGrid: PropTypes.bool, // Flag to set whether element is inside a grid or not
  isAddCard: PropTypes.bool, // Flag to set whether element should be add Card or regular card
  profileName: PropTypes.string, // Profile name to show
  avatar: PropTypes.string, // Avatar image source
  editMode: PropTypes.bool, // Flag to set whether edit mode is enabled or not
  addLink: PropTypes.string, // Route to redirect when user clicks on add Profile card
  editLink: PropTypes.string, // Route to redirect when user clicks on edit Profile card
  displayName: PropTypes.bool,
  profileUpdatedInfo: PropTypes.shape({
    avatar: PropTypes.string,
  }),
  setProfileUpdatedInfo: PropTypes.func,
}

ProfileCard.defaultProps = {
  id: '',
  isInGrid: true,
  isAddCard: false,
  profileName: 'Profile Name',
  avatar: 'Loading...',
  editMode: false,
  addLink: '/',
  editLink: '/',
  displayName: true,
  profileUpdatedInfo: undefined,
  setProfileUpdatedInfo: () => {},
}

export default ProfileCard
