import React from 'react'
import PropTypes from 'prop-types'
import avatarsJson from '../../../../assets/json/avatars.json'
import './avatarsManage.scss'
import Button from '../../extraComponents/button/Button'

const avatarsCards = ({ setNewIcon, setShowAvatars }) => {
  const updateImage = (icon) => {
    setNewIcon(icon)
    setShowAvatars(false)
  }

  return (
    avatarsJson.map((avatar) => (
      <div key={avatar.id} className="cards">
        <h1 className="cards-title">{avatar.section}</h1>

        <div className="cards-principal">
          {avatar.icons.map((icon) => (
            <div className="cards-carousel">
              <Button text="avatars" classButton="carousel" iconClass="avatar" onClick={() => updateImage(icon.imageSrc)} icon={icon.imageSrc} iconAlt={icon.id} />
            </div>
          ))}
        </div>
      </div>
    ))
  )
}

avatarsCards.propTypes = {
  setNewIcon: PropTypes.func,
  setShowAvatars: PropTypes.func,
}

avatarsCards.defaultProps = {
  setNewIcon: () => {},
  setShowAvatars: () => {},
}

export default avatarsCards
