/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

const Tags = ({ tagsList }) => (
  <div className="tags">
    {
        tagsList.map((item, index) => (item !== undefined ? (
          <div className="tag-border" key={`${item}-${index}`}>
            <div className="tag">
              {item}
            </div>
          </div>
        ) : ''))
    }
  </div>
)

Tags.propTypes = {
  tagsList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Tags
