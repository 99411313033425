/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const userInitialState = {
  currentContentInfo: '',
  selectedSeason: '',
  episodesInfo: '',
  searchTerm: '',
  recommendationsCarousel: [],
  showResults: {
    id: '5000',
    name: 'Search',
    type: 'normal',
    items: [],
  },
}

const contentSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setCurrentContentInfo: (state, action) => { },
    setRecommendations: (state, action) => { },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setSearchData: (state, action) => {
      state.showResults = action.payload.content
    },
    setEpisodesInfo: (state, action) => {
      state.episodesInfo = action.payload.content
    },
    setSelectedSeason: (state, action) => {
      state.selectedSeason = action.payload.content
    },
    setContentData: (state, action) => {
      state.currentContentInfo = action.payload.content.contentInfo
      state.selectedSeason = action.payload.content.selectedSeason
      state.episodesInfo = action.payload.content.episodesInfo
      state.recommendationsCarousel = action.payload.content.recommendations
    },
  },
})

export const contentActions = contentSlice.actions

export default contentSlice
