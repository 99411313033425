import React from 'react'
import PropTypes from 'prop-types'
import Episode from './Episode'

const Episodes = ({ episodes }) => (
  <div className="episodes">
    {episodes.map((item) => <Episode key={item.id} episode={item} />)}
  </div>
)

Episodes.propTypes = {
  episodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cast: PropTypes.arrayOf(PropTypes.string).isRequired,
      ageRestriction: PropTypes.string.isRequired,
      imageSrcPreview: PropTypes.shape({
        layout: PropTypes.string,
        '16x9': PropTypes.string,
      }).isRequired,
      duration: PropTypes.string.isRequired,
      synopsis: PropTypes.string.isRequired,
      seasonNumber: PropTypes.number.isRequired,
      episodeNumber: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
}

export default Episodes
