var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var containerAdaptor = function (container) {
    var containerVm = {
        id: container._id,
        name: container.name.value,
        type: container.carouselType,
        items: container.resources && container.resources.map(function (res) {
            return resourceAdaptor(res);
        }),
    };
    return containerVm;
};
export var resourceAdaptor = function (resource) {
    var _a, _b, _c, _d, _e, _f;
    var resourceVm = {
        id: (resource === null || resource === void 0 ? void 0 : resource._id) || '',
        name: ((_a = resource === null || resource === void 0 ? void 0 : resource.name) === null || _a === void 0 ? void 0 : _a.value) || '',
        genres: ((_b = resource === null || resource === void 0 ? void 0 : resource.genres) === null || _b === void 0 ? void 0 : _b.value) || [],
        cast: (resource === null || resource === void 0 ? void 0 : resource.cast) || [],
        contentType: (resource === null || resource === void 0 ? void 0 : resource.contentType) || undefined,
        ageRestriction: (resource === null || resource === void 0 ? void 0 : resource.rating) || undefined,
        providerName: (resource === null || resource === void 0 ? void 0 : resource.providerName) || undefined,
        releaseYear: (resource === null || resource === void 0 ? void 0 : resource.year) || 0,
        duration: (resource === null || resource === void 0 ? void 0 : resource.duration) || undefined,
        synopsis: ((_c = resource === null || resource === void 0 ? void 0 : resource.description) === null || _c === void 0 ? void 0 : _c.value) || undefined,
        seasons: (resource === null || resource === void 0 ? void 0 : resource.seasons) || [],
        seasonNumber: (resource === null || resource === void 0 ? void 0 : resource.seasonNumber) || undefined,
        episodeNumber: (resource === null || resource === void 0 ? void 0 : resource.episodeNumber) || undefined,
        imageSrcCarousel: Object.assign.apply(Object, __spreadArray([{}, {}], (_d = resource === null || resource === void 0 ? void 0 : resource.images) === null || _d === void 0 ? void 0 : _d.filter(function (image) { return image.layout === 'carousel'; }), false)) || {},
        imageSrcPreview: Object.assign.apply(Object, __spreadArray([{}, {}], (_e = resource === null || resource === void 0 ? void 0 : resource.images) === null || _e === void 0 ? void 0 : _e.filter(function (image) { return image.layout === 'preview'; }), false)) || {},
        imageSrcBanner: Object.assign.apply(Object, __spreadArray([{}, {}], (_f = resource === null || resource === void 0 ? void 0 : resource.images) === null || _f === void 0 ? void 0 : _f.filter(function (image) { return image.layout === 'banner'; }), false)) || {},
    };
    return resourceVm;
};
