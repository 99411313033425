/* eslint-disable no-unused-vars */
import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import Button from '../../extraComponents/button/Button'
import arrow from '../../../../assets/images/arrow.png'

const Actions = {
  Increment: 'increment',
  Decrement: 'decrement',
}

const arrowMovement = (state, action) => {
  const margin = 1
  const carouselWidth = 90

  switch (action.type) {
    case Actions.Increment:
      // If user is in last slide return to first slide
      if (state === action.payload.carouselLength) {
        action.payload.setStyles({ right: `-${margin}vw` })
        return 0
      }
      // Else: show next slide
      action.payload.setStyles({ right: `${carouselWidth * (state + 1) - margin}vw` })
      return state + 1
    case Actions.Decrement:
      // If user is on first slide, return to last slide
      if (state === 0) {
        action.payload.setStyles({ right: `${carouselWidth * action.payload.carouselLength - margin}vw` })
        return action.payload.carouselLength
      }
      // Else: show prev slide
      action.payload.setStyles({ right: `${carouselWidth * (state - 1) - margin}vw` })
      return state - 1
    default:
      throw new Error()
  }
}

const Arrows = ({
  carouselType, setStyles, carouselLength, touchStart,
}) => {
  const [state, dispatch] = useReducer(arrowMovement, 0)

  const goLeft = () => {
    if (!touchStart && carouselLength > 0) {
      dispatch({ type: Actions.Decrement, payload: { setStyles, carouselLength } })
    }
  }
  const goRight = () => {
    if (!touchStart && carouselLength > 0) {
      dispatch({ type: Actions.Increment, payload: { setStyles, carouselLength } })
    }
  }

  return (
    <>
      <div className={`arrow left ${carouselType}`}>
        {
          touchStart ? '' : <Button icon={arrow} onClick={() => goLeft()} />
        }
      </div>
      <div className={`arrow right ${carouselType}`}>
        {
          touchStart ? '' : <Button icon={arrow} onClick={() => goRight()} />
        }
      </div>
    </>
  )
}

Arrows.propTypes = {
  carouselType: PropTypes.string.isRequired,
  setStyles: PropTypes.func.isRequired,
  carouselLength: PropTypes.number.isRequired,
  touchStart: PropTypes.bool,
}

Arrows.defaultProps = {
  touchStart: false,
}

export default Arrows
