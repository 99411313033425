/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './navbar.scss'
import Button from '../button/Button'
import tabsJson from '../../../../assets/json/tabs.json'
import openMenuIcon from '../../../../assets/images/open-menu.png'
import openCloseIcon from '../../../../assets/images/close-menu.png'
import avatarIcon from '../../../../assets/images/profile.png'
import Dropdown from '../dropdown/Dropdown'
import SearchBar from '../search/SearchBar'

const Navbar = () => {
  const [open, setOpen] = React.useState(false)
  const [tabs, setTabs] = useState([])
  const [isPhone, setIsPhone] = useState(false)
  const location = useLocation()

  const showNavbar = useSelector((state) => state.user.showNavbar)
  const profileSelected = useSelector((state) => state.user.profileSelected)

  const openMenu = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setIsPhone(true)
      } else {
        setIsPhone(false)
      }
    }

    handleResize() // Change ia on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setTabs(tabsJson)
  }, [tabsJson])

  return (
    showNavbar
      && (
        <div className={`${open ? 'navbar-menu-mobile' : 'navbar'}`}>
          <div className="navbar-menu-mobile-header">
            <div className="navbar-open-icon-container">
              <button
                className="open-icon"
                type="button"
                onClick={() => openMenu()}
              >
                <img src={open ? openCloseIcon : openMenuIcon} alt="menu icon" />
              </button>
            </div>
            {!open ? <SearchBar shouldBeOpen /> : ''}
          </div>
          <div className={`navbar-menu-mobile-container ${(location.pathname.includes('details') && !isPhone) || location.pathname.includes('player') ? 'details' : ''}`}>
            {!isPhone
              ? null
              : (
                <section className="navbarButton tabs-section" key="user-info">
                  <div className="account-mobile-dislplay">
                    <img alt="user-avatar" src={profileSelected.avatarSrc ?? avatarIcon} />
                    <span>
                      { profileSelected.profileName ?? 'Account'}
                    </span>
                  </div>
                </section>
              )}
            <div className="left-container">
              {
              tabs.map((section) => (

                section.tabs?.length > 0 ? (

                  <section className="navbarButton tabs-section" key={section.title}>
                    <span className="title-section">{section.title}</span>
                    {
                      section.tabs?.map((tab) => (
                        <Button
                          iconAlt={tab.tabName}
                          key={tab.tabId}
                          icon={tab.icon}
                          text={tab.tabName}
                          buttonType="tabRedirect"
                        />
                      ))
                    }
                  </section>
                ) : ''
              ))
            }
            </div>
            <div className="right-container">
              {!isPhone ? <SearchBar /> : ''}
              <Dropdown />
            </div>
          </div>
        </div>
      )
  )
}

export default Navbar
