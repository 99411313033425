import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../extraComponents/button/Button'
import Banner from '../../carouselRelated/banner/Banner'
import AutoScrollCarousel from '../../carouselRelated/autoscrollCarousel/AutoScrollCarousel'
import Plans from '../plan/Plans'
import Faq from '../faq/Faq'
import './home.scss'
import { userActions } from '../../../store/user/user-slice'

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.setShowNavbar(false))
  }, [dispatch])

  const plansRef = useRef()
  const faqRef = useRef()
  const bannerRef = useRef()
  const carouselRef = useRef()

  const handleScrollClick = () => {
    plansRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleFaqsScroll = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleTopScroll = () => {
    bannerRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleCarouselScroll = () => {
    carouselRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Button classButton="sign-in-button" text="Sign In" />
      <div className="banner-separator" ref={bannerRef}>
        <Banner buttonFunc={handleScrollClick} />
      </div>
      <div className="separator">
        <div ref={plansRef} className="plans-separator">
          <Plans buttonFunc={handleCarouselScroll} />
        </div>
        <div ref={carouselRef} className="carousel-separator">
          <AutoScrollCarousel buttonFunc={handleFaqsScroll} />
        </div>
        <div ref={faqRef} className="faqs-separator">
          <Faq buttonFunc={handleTopScroll} />
        </div>
      </div>
    </>
  )
}

export default Home
