/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom'
import { Config } from '@cleeng/mediastore-sdk'
import { useSelector, useDispatch } from 'react-redux'
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/extraComponents/navbar/Navbar'
import Home from './components/homeRelated/home/Home'
import ChangePlan from './components/settingsRelated/changePlan/ChangePlan'
import VODHome from './components/homeRelated/home/VODHome'
import Details from './components/detailsRelated/DetailsScreen'
import './theme.scss'
import Search from './components/searchRelated/Search'
import SignUpCleeng from './components/accountRelated/signUp/SignUpCleeng'
import SignInCleeng from './components/accountRelated/signIn/SignInCleeng'
import ForgotPasswordCleeng from './components/accountRelated/forgotPassword/ForgotPasswordCleeng'
import SettingCleeng from './components/settingsRelated/settings/SettingsCleeng'
import Profiles from './components/profileRelated/profilesScreen/Profiles'
import AvatarsManage from './components/profileRelated/avatars/avatarsManage'
import CreateProfile from './components/profileRelated/profile/CreateProfile'
import EditProfile from './components/profileRelated/profile/EditProfile'
import { deepLinkActions } from './store/deepLink/deep-link-slice'
import Player from './components/player/Player'

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const profileSelected = useSelector((state) => state.user.profileSelected)
  const { pathname } = document.location
  const dispatch = useDispatch()

  Config.setEnvironment('sandbox')
  Config.setPublisher('898676925')
  Config.setOffer('S531234647_PL')
  Config.setMyAccountUrl('http://localhost:8080/settings')
  Config.setPaypalUrls({
    successUrl: 'http://localhost:8080/settings',
    cancelUrl: 'http://localhost:8080/',
    errorUrl: 'http://localhost:8080/',
  })

  Config.setTheme({
    successColor: '#743ad5',
  })

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('loggedIn:', isLoggedIn)
  }, [isLoggedIn])

  useEffect(() => {
    if (!profileSelected?._id) {
      ['details', 'search', 'changePlan'].forEach((path) => {
        if (pathname.includes(path)) {
          dispatch(deepLinkActions.setNextAction(pathname))
        }
      })
    }
  }, [])

  if (isLoggedIn && profileSelected?._id) {
    return (
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route
            path="/browse"
            element={(<VODHome />)}
          />
          <Route
            path="/changePlan"
            element={(<ChangePlan />)}
          />
          <Route
            path="/details/:id"
            element={(<Details />)}
          />
          <Route
            path="/search"
            element={(<Search />)}
          />
          <Route
            path="/chooseProfile"
            element={(<Profiles />)}
          />
          <Route
            path="/chooseAvatars"
            element={(<AvatarsManage />)}
          />
          <Route
            path="/editProfile"
            element={(<EditProfile />)}
          />
          <Route
            path="/createProfile"
            element={(<CreateProfile />)}
          />
          <Route
            path="/settings"
            element={(<SettingCleeng />)}
          />
          <Route
            path="/player/:id"
            element={(<Player />)}
          />
          <Route path="*" element={<Navigate to="/chooseProfile" />} />
        </Routes>
      </Router>
    )
  } if (isLoggedIn) {
    return (
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route
            path="/chooseProfile"
            element={(<Profiles />)}
          />
          <Route
            path="/settings"
            element={(<SettingCleeng />)}
          />
          <Route
            path="/createProfile"
            element={(<CreateProfile />)}
          />
          <Route path="*" element={<Navigate to="/chooseProfile" />} />
        </Routes>
      </Router>
    )
  }
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={(
            <Home />
          )}
        />
        <Route
          path="/signIn"
          element={(
            <SignInCleeng />
          )}
        />
        <Route
          path="/signUp"
          element={(
            <SignUpCleeng />
          )}
        />
        <Route
          path="/forgotPassword"
          element={(
            <ForgotPasswordCleeng />
          )}
        />
        <Route
          path="/details/:id"
          element={(<Navigate to="/signIn" />)}
        />
        <Route
          path="/changePlan"
          element={<Navigate to="/signIn" />}
        />
        <Route
          path="/browse"
          element={<Navigate to="/signIn" />}
        />
        <Route
          path="/search"
          element={<Navigate to="/signIn" />}
        />
        <Route
          path="/settings"
          element={(<SettingCleeng />)}
        />
        <Route path="*" element={<Navigate to="/signIn" />} />
      </Routes>
    </Router>
  )
}

export default App
