/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const userInitialState = {
  userData: {},
  profileData: {},
  profileSelected: {},
  profiles: [],
  showNavbar: false,
  showResults: '',
  changed: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload.user
      state.profiles = action.payload.profiles
    },
    setShowNavbar: (state, action) => {
      state.showNavbar = action.payload
    },
    setSelectedProfile: (state, action) => {
      state.profileSelected = action.payload.profileSelected
    },
    clearUserState: (state) => {
      state.userData = {}
      state.profileData = {}
      state.profileSelected = ''
      state.profiles = []
      state.showNavbar = false
      state.showResults = ''
      state.changed = false
    },
  },
})

export const userActions = userSlice.actions

export default userSlice
