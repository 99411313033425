/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const storefrontInitialState = {
  storefrontCarousels: undefined,
  storefrontHero: undefined,
  scrollHome: 0,
}

const storefrontSlice = createSlice({
  name: 'storefront',
  initialState: storefrontInitialState,
  reducers: {
    setCarouselsContainers: (state, action) => {
      state.storefrontCarousels = action.payload.content
    },
    setHeroContainer: (state, action) => {
      state.storefrontHero = action.payload.content
    },
    setScrollHome: (state, action) => {
      state.scrollHome = action.payload
    },
  },
})

export const storefrontActions = storefrontSlice.actions

export default storefrontSlice
