/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import './infoBox.scss'
import pencilImg from '../../../../assets/images/whitePencil.png'

const InfoBox = ({
  title, subtitle, route, isCheckbox, defaultValue, changeHandler,
}) => {
  const navigate = useNavigate()
  const redirectTo = (text) => {
    navigate(`/${text}`)
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
          isCheckbox ? (
            <div className="container-title languages-infoBox">
              <div className="title">
                {title}
              </div>
              <div className="subtitle">
                <label htmlFor="checkbox-label" value="Text Message (SMS)" className="container-checkbox">
                  <input type="checkbox" checked={defaultValue} id="checkbox-label" name="fav_language" className="checkbox-input" onChange={changeHandler} />
                  <span className="checkbox-text" />
                  {subtitle}
                </label>
              </div>
            </div>
          ) : (
            <div className="container-title">
              <div className="title">
                {title}
              </div>
              <div className="subtitle">
                {subtitle}
                <img className="pencil-floating-button2" src={pencilImg} alt="pencil" onClick={() => redirectTo(route)} />
              </div>
            </div>
          )
      }
    </>
  )
}
InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  route: PropTypes.string,
  isCheckbox: PropTypes.bool,
  defaultValue: PropTypes.bool,
}
InfoBox.defaultProps = {
  isCheckbox: false,
  route: '',
  defaultValue: false,
  changeHandler: () => {},
}

export default InfoBox
