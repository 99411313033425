/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const deepLinkInitialState = {
  nextAction: '',
}

const deepLinkSlice = createSlice({
  name: 'deeplink',
  initialState: deepLinkInitialState,
  reducers: {
    setNextAction(state, action) {
      state.nextAction = action.payload
    },
  },
})

export const deepLinkActions = deepLinkSlice.actions

export default deepLinkSlice
