/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { userActions } from './user-slice'
import { authActions } from '../auth/auth-slice'
import { getProfiles, getProfile } from '../../services/account/account.service.tsx'

export const fetchUserData = () => async (dispatch) => {
  const userProfiles = await getProfiles(localStorage.getItem('CLEENG_CUSTOMER_ID'))
  dispatch(
    userActions.setUserData({
      profiles: userProfiles,
    }),
  )
  return userProfiles
}

export const fetchProfileData = (profileID) => async (dispatch) => {
  const userProfile = await getProfile(localStorage.getItem('CLEENG_CUSTOMER_ID'), profileID)

  dispatch(
    userActions.setSelectedProfile({
      profileSelected: userProfile,
    }),
  )
  return userProfile
}
