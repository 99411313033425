/* eslint-disable import/prefer-default-export */
import getCustomerSubscriptionsAsync from '../cleeng/customer/getCustomerSubscriptions'
import { authActions } from './auth-slice'

export const fetchUserSubscription = () => async (dispatch) => {
  const fetchSubscription = async () => {
    const res = await getCustomerSubscriptionsAsync()
    const userSubscription = res?.responseData?.items[0]?.status === 'active'

    return userSubscription
  }

  const isSubscribed = await fetchSubscription()
  dispatch(
    authActions.setSubscription({
      content: isSubscribed,
    }),
  )
  return isSubscribed
}
