import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import faqContainers from '../../../../assets/json/faqItems.json'
import plus from '../../../../assets/images/plus.png'
import minus from '../../../../assets/images/minus.png'
import './faq.scss'
import backArrow from '../../../../assets/images/backArrow.png'
import Button from '../../extraComponents/button/Button'

const Faq = ({ buttonFunc }) => {
  const heroRef = React.useRef()
  const [active, setActive] = React.useState(0)
  const { t } = useTranslation('common')

  const openItem = (index) => {
    // Expand faq item
    if (active === index) {
      setActive(-1)
    } else {
      setActive(index)
    }
  }

  const closeItem = (index, event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      openItem(index)
    }
  }

  return (
    <div className="faq">
      <div className="faq-title">{t('faq.title')}</div>
      <div className="faq-list" ref={heroRef}>
        {
            faqContainers?.items.map((item, index) => (
              <div
                key={item.id}
                className={`${index === active ? 'item-active' : 'item'}`}
                type="button"
              >
                <button
                  className="faq-head"
                  onClick={() => openItem(index)}
                  onKeyPress={(e) => closeItem(index, e)}
                  type="button"
                >
                  <h1>
                    {item.question}
                  </h1>
                  <div className="img-container">
                    <img
                      alt="expand-icon"
                      src={`${index === active ? minus : plus}`}
                    />

                  </div>
                </button>
                <p>
                  {item.answer}
                </p>
              </div>
            ))
        }
      </div>
      <Button classButton="go-to-top-arrow" icon={backArrow} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

Faq.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default Faq
