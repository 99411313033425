import React from 'react'
import PropTypes from 'prop-types'

const AvatarsImages = ({ imgSrc, altImg, className }) => (
  <img className={className} src={imgSrc} alt={altImg} />
)

AvatarsImages.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  altImg: PropTypes.string.isRequired,
  className: PropTypes.string,
}

AvatarsImages.defaultProps = {
  className: '',
}

export default AvatarsImages
