/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { userActions } from '../../store/user/user-slice'
import Button from '../extraComponents/button/Button'
import BitmovinPlayer from './BitmovinPlayer'
import './player.scss'
import PlayerBottom from './playerBottom/PlayerBottom'
import backArrow from '../../../assets/images/arrow.png'
import { fetchCurrentContent } from '../../store/content/content-actions'
import Loader from '../extraComponents/loader/Loader'

const Player = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentContentInfo = useSelector((state) => state.content.currentContentInfo)

  const [contentMetadata, setContentMetadata] = useState()
  const [showControllers, setShowControllers] = useState(true)
  const [timer, setTimer] = useState()
  const [isOnPause, setIsOnPause] = useState(false)

  useEffect(() => {
    dispatch(userActions.setShowNavbar(false))
    if (currentContentInfo.contentInfo
      && location.pathname.includes(currentContentInfo.contentInfo.id)) {
      setContentMetadata(currentContentInfo.contentInfo)
    } else {
      dispatch(fetchCurrentContent(location.pathname.split('/')[2])).then((contentDataResult) => {
        setContentMetadata(contentDataResult.contentInfo)
      })
    }
  }, [])

  // show controllers on user mouse movement
  const updateControllers = () => {
    setShowControllers(true)
    clearTimeout(timer)
    if (!isOnPause) {
      setTimer(setTimeout(() => {
        setShowControllers(false)
      }, 2000))
    }
  }

  // show controllers on pause
  const onPause = () => {
    setIsOnPause(!isOnPause)
  }

  useEffect(() => {
    if (!isOnPause) {
      clearTimeout(timer)
      setTimer(setTimeout(() => {
        setShowControllers(false)
      }, 2200))
    } else {
      setShowControllers(true)
    }
  }, [isOnPause])

  return (
    !contentMetadata
      ? (
        <Loader />
      )
      : (
        <div
          className="player-container"
          onMouseMove={() => updateControllers()}
          onClick={() => onPause()}
        >
          <Button classButton="player-back" icon={backArrow} onClick={() => navigate(-1)} />
          <BitmovinPlayer />
          <PlayerBottom
            showControllers={showControllers}
            title={contentMetadata.name}
            subtitle={contentMetadata.contentType === 'tvshow'
              ? `S${contentMetadata.seasonNumber} : E${contentMetadata.episodeNumber}`
              : undefined}
          />
        </div>
      )
  )
}

export default Player
