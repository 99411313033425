/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { getEnglishName } from 'all-iso-language-codes'
import './editProfile.scss'
import InfoBox from '../../extraComponents/infobox/InfoBox'
import ProfileCard from '../profileCard/ProfileCard'
import LanguageDropdown from '../../extraComponents/languageDropdown/LanguageDropdown'
import languages from '../../../../assets/json/languages.json'
import { userActions } from '../../../store/user/user-slice'
import { AddContactRequestModel } from '../../../services/account/model/add-profile.request.model.tsx'
import { updateProfile, deleteProfile } from '../../../services/account/account.service.tsx'

const EditProfile = () => {
  const profileSelected = useSelector((state) => state.user.profileSelected)
  const languagePrefer = languages.find((lang) => lang.key === profileSelected.prefLang.ISO)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    dispatch(userActions.setShowNavbar(true))
  }, [dispatch])

  const [profileUpdatedInfo, setProfileUpdatedInfo] = useState({
    name: profileSelected.profileName,
    avatar: profileSelected.avatarSrc,
    maturityRating: profileSelected.maturityRating,
    language: languagePrefer,
    hasAutoPlay: profileSelected.hasAutoPlay,
  })
  const navigate = useNavigate()
  const redirectTo = (text) => {
    if (text === '') {
      navigate(-1)
    } else {
      navigate(`/${text}`)
    }
  }

  const handleNameChange = (ev) => {
    setProfileUpdatedInfo({
      ...profileUpdatedInfo,
      name: ev.target.value,
    })
  }

  const handleLanguageChange = (newLan) => {
    setProfileUpdatedInfo({
      ...profileUpdatedInfo,
      language: newLan,
    })
    i18n.changeLanguage(newLan.key)
  }

  const handleAutoPlayChange = () => {
    setProfileUpdatedInfo({
      ...profileUpdatedInfo,
      hasAutoPlay: !profileUpdatedInfo.hasAutoPlay,
    })
  }

  const editProfile = async () => {
    const request = new AddContactRequestModel()
    request.profileName = profileUpdatedInfo.name
    request.avatarSrc = profileUpdatedInfo.avatar
    request.maturityRating = profileUpdatedInfo.maturityRating
    request.hasAutoPlay = profileUpdatedInfo.hasAutoPlay
    request.prefLang = profileUpdatedInfo.language.key

    await updateProfile(localStorage.getItem('CLEENG_CUSTOMER_ID'), profileSelected._id, request)
    redirectTo('chooseProfile')
  }

  const removeProfile = async () => {
    await deleteProfile(localStorage.getItem('CLEENG_CUSTOMER_ID'), profileSelected._id)
    dispatch(
      userActions.setSelectedProfile({
        profileSelected: '',
      }),
    )
    redirectTo('chooseProfile')
  }

  useEffect(() => () => {
    const finalLanguage = languages.find((lang) => lang.key === profileSelected.prefLang.ISO).key
    i18n.changeLanguage(finalLanguage)
  }, [])

  const infoIsValid = () => profileUpdatedInfo.name !== '' && profileUpdatedInfo.avatar !== '' && profileUpdatedInfo.maturityRating !== ''

  return (
    <div className="editProfile">
      <div className="main-container">
        <div className="title-account">
          {t('profiles.edit')}
        </div>
        <div className="data-container">
          <div className="photo-container">
            <div className="avatar-container">
              <ProfileCard
                // eslint-disable-next-line no-underscore-dangle
                id={profileSelected._id}
                isInGrid={false}
                editMode
                avatar={profileUpdatedInfo.avatar}
                profileName={profileUpdatedInfo.name}
                profileUpdatedInfo={profileUpdatedInfo}
                setProfileUpdatedInfo={setProfileUpdatedInfo}
                editLink="/chooseAvatars"
                displayName={false}
              />
            </div>
            <div className="delete-btn-container">
              <button type="button" className="btn-delete" onClick={() => removeProfile()}><strong>{t('profiles.delete')}</strong></button>
            </div>
          </div>
          <div className="settings-container">
            <input className="profile-name-input" name="name" type="input" placeholder={t('inputs.name')} value={profileUpdatedInfo.name} onChange={(e) => handleNameChange(e)} />
            <div className="language-title">{t('settings.language')}</div>
            <LanguageDropdown
              valueState={profileUpdatedInfo.language}
              contentToShow={languages}
              maxColumns={3}
              changeHandler={handleLanguageChange}
            />
            <InfoBox title={t('settings.maturity.title')} subtitle={profileSelected.maturityRating} route="" />
            <InfoBox title={t('settings.autoplay.title')} subtitle={t('settings.autoplay.subtitle')} defaultValue={profileUpdatedInfo.hasAutoPlay} changeHandler={handleAutoPlayChange} isCheckbox />
          </div>
        </div>
        <div className="buttons-container">
          <button type="button" className="btn-save" disabled={!infoIsValid()} onClick={() => editProfile()}><strong>{t('buttons.save')}</strong></button>
          <button type="button" className="btn-cancel" onClick={() => redirectTo('chooseProfile')}><strong>{t('buttons.cancel')}</strong></button>
        </div>
      </div>
    </div>
  )
}

export default EditProfile
