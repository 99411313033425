import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ButtonArrow = ({ id, imgSrc, className }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const buttonBehaviour = (text) => {
    if (location.pathname !== `/${text}`) {
      navigate(-1)
    }
  }

  return (
    <button
      className={className}
      type="button"
      onClick={() => buttonBehaviour('')}
    >
      <img src={imgSrc} alt={id} />
    </button>
  )
}

ButtonArrow.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default ButtonArrow
