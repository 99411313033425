import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MoreDetails = ({ currentContentInfo }) => {
  const { t } = useTranslation('common')

  return (
    <div className="more-details">
      <div className="left-column">
        <div className="complete-synopsis">
          <p>
            {currentContentInfo.synopsis}
          </p>
        </div>
      </div>
      <div className="right-column">
        <div className="left-column">
          <div className="duration">
            <h5>{t('details.duration')}</h5>
            <h5 className="detailed">{currentContentInfo.duration}</h5>
          </div>
          <div className="release">
            <h5>{t('details.release')}</h5>
            <h5 className="detailed">{currentContentInfo.releaseYear}</h5>
          </div>
          <div className="age-restriction">
            <h5>{t('details.rating')}</h5>
            <div className="rating">
              <h5 className="detailed">{currentContentInfo.ageRestriction}</h5>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="cast">
            <h5>{t('details.cast')}</h5>
            <div className="cast-list">
              {
                currentContentInfo.cast.map((actress) => (
                  <h5 className="detailed" key={actress}>{actress}</h5>
                ))
              }
            </div>
          </div>
          <div className="genres">
            <h5>{t('details.genres')}</h5>
            <div className="genres-list">
              {
                currentContentInfo.genres.map((genre) => (
                  <h5 className="detailed" key={genre}>{genre}</h5>
                ))
              }
            </div>
          </div>
          <div className="provider">
            <h5>{t('details.provider')}</h5>
            <h5 className="detailed">{currentContentInfo.providerName ?? ''}</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

MoreDetails.propTypes = {
  currentContentInfo: PropTypes.shape({
    synopsis: PropTypes.string.isRequired,
    cast: PropTypes.arrayOf(PropTypes.string).isRequired,
    duration: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    releaseYear: PropTypes.number.isRequired,
    genres: PropTypes.arrayOf(PropTypes.string).isRequired,
    ageRestriction: PropTypes.string.isRequired,
  }).isRequired,
}

export default MoreDetails
