/* eslint-disable import/prefer-default-export */
import { fetchContainers } from '../../services/storefront/storefront.service.tsx'
import { storefrontActions } from './storefront-slice'

export const fetchStorefront = (prefLang) => async (dispatch) => {
  const fetchContainersCarousels = async () => {
    const containers = await fetchContainers(prefLang)

    return {
      heroCarousel: containers.find((container) => container.name === 'Hero'),
      carouselContainers: containers.filter((container) => container.name !== 'Hero'),
    }
  }
  const containers = await fetchContainersCarousels()

  dispatch(
    storefrontActions.setHeroContainer({
      content: containers.heroCarousel,
    }),
  )
  dispatch(
    storefrontActions.setCarouselsContainers({
      content: containers.carouselContainers,
    }),
  )
  return containers
}
