/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { storefrontActions } from '../../../store/storefront/storefront-slice'
import backArrow from '../../../../assets/images/backArrow.png'
import playIcon from '../../../../assets/images/playIcon.png'
import forwardArrow from '../../../../assets/images/forwardArrow.png'
import './hero.scss'

const Hero = ({ carousel }) => {
  const heroRef = React.useRef()
  const [active, setActive] = React.useState(0)
  const [isSquareAspect, setIsSquareAspect] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    let autoScroll

    if (carousel) {
      autoScroll = setInterval(() => {
        setActive((oldActive) => (oldActive + 1) % carousel.items.length)
      }, 5000)
    }
    return () => {
      clearInterval(autoScroll)
    }
  }, [carousel])

  useEffect(() => {
    if (heroRef?.current) heroRef.current.scrollLeft = heroRef.current.offsetWidth * active
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 950 && window.screen.availHeight > 500) {
        setIsSquareAspect(true)
      } else {
        setIsSquareAspect(false)
      }
    }

    handleResize() // Change ia on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const goNext = () => {
    // Move to hero item to the right
    setActive((active + 1) % carousel.items.length)
  }

  const goBack = () => {
    // Move to hero item to the left
    setActive(active !== 0 ? active - 1 : carousel.items.length - 1)
  }

  const goTo = (index) => {
    // Move to hero item
    setActive(index)
  }

  const redirectTo = () => {
    const card = carousel.items[active]
    dispatch(storefrontActions.setScrollHome(window.scrollY))
    navigate(`/details/${card.id}`)
  }

  return (
    <div className="hero">
      {
      carousel?.items
        ? (
          <>
            <button type="button" onClick={() => goBack()} className="left-arrow">
              <img alt="left-arrow" src={backArrow} />
            </button>
            <div className="hero-list" ref={heroRef}>
              {
                carousel?.items?.map((item, index) => (
                  <button
                    key={item.id}
                    alt="hero-item"
                    className="hero-item"
                    type="button"
                    style={{
                      left: `${index * 100}%`,
                      background: ` linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),
                    url(${isSquareAspect ? item.imageSrcCarousel['1x1'] : item.imageSrcCarousel['3x1']})`,
                    }}
                  >
                    <div className="hero-description-container">
                      <h1 className="hero-title">
                        {item.name.toUpperCase()}
                      </h1>
                      <span className="hero-description">
                        { item?.synopsis }
                      </span>
                      <div className="buttons-container">
                        <div className="hero-play-button" onClick={() => navigate(`/player/${item.id}`)}>
                          <img src={playIcon} alt="more-info" />
                        </div>
                        <div className="hero-more-info" onClick={() => redirectTo()}>More  Info</div>
                      </div>
                    </div>
                  </button>
                ))
              }
            </div>
            <button type="button" onClick={() => goNext()} className="right-arrow">
              <img alt="right-arrow" src={forwardArrow} />
            </button>
            <div className="dot-list">
              {
                [...Array(carousel?.items?.length)]?.map((x, index) => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <button key={`dot-${index.toString()}`} onClick={() => goTo(index)} type="button" className={`${index === active ? 'dot-active' : 'dot'}`} />
                ))
              }
            </div>
          </>
        )
        : ''
    }
    </div>
  )
}

Hero.propTypes = {
  carousel: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        genres: PropTypes.arrayOf(PropTypes.string),
        synopsis: PropTypes.string,
        imageSrcCarousel: PropTypes.shape({
          layout: PropTypes.string,
        }).isRequired,
      }),
    ),
  }).isRequired,
}

export default Hero
