/* eslint-disable no-console */
import React, {
  useState, useRef, useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@cleeng/mediastore-sdk'
import Button from '../button/Button'
import logoutIcon from '../../../../assets/images/logout.png'
import settingsIcon from '../../../../assets/images/settings.png'
import changeProfileIcon from '../../../../assets/images/changeProfile.png'
import './dropdown.scss'
import { authActions } from '../../../store/auth/auth-slice'

const Dropdown = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setIsOpen] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const dropdownRef = useRef(null)
  const profileSelected = useSelector((state) => state.user.profileSelected)

  // Dropdown click
  useEffect(() => {
    // Close menu when user clicks outside
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    // add listener only if there is a dropdown
    if (open && !isSmallScreen) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [open, isSmallScreen])

  // Checking if it is a small screen
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setIsSmallScreen(true)
        setIsOpen(true)
      } else {
        setIsSmallScreen(false)
        setIsOpen(false)
      }
    }

    handleResize() // Change screen size on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const goToSettings = () => {
    if (!isSmallScreen) {
      setIsOpen(false)
    }
    navigate('/settings')
  }

  const logoutHandler = () => {
    const isMyAccount = window.location.pathname.includes('settings')
    Auth.logout(isMyAccount)
    dispatch(authActions.logout())
    navigate('/')
  }

  return (
    <ul ref={dropdownRef} className={`navbarButton ${isSmallScreen ? 'tabs-section' : 'dropdown'} ${open ? 'open-dropdown' : ''}`}>
      {
        isSmallScreen
          ? <span className="title-section">{profileSelected.profileName}</span>
          : <Button text={profileSelected.profileName} buttonType="settings" icon={profileSelected.avatarSrc} onClick={() => setIsOpen(!open)} />
      }
      {
          open
            ? (
              <li className="dropdown-elements">
                <Button text="Settings" onClick={goToSettings} icon={settingsIcon} />
                <Button buttonType="changeProfile" text="Change Profile" icon={changeProfileIcon} />
                <Button buttonType="logout" onClick={logoutHandler} text="Sign Out" icon={logoutIcon} />
              </li>
            ) : (
              ''
            )
      }
    </ul>
  )
}

export default Dropdown
